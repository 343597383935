const AllContentView = () =>
  import(
    /*webpackChunkName: "AllContentView"*/ "../../../views/sales/AllContent.vue"
  );
export default {
  path: "browse",
  name: "browse-all-content",
  alias: ["navegar"],
  component: AllContentView,
  meta: { requiresAuth: false, requiresEntitlement: false },
};
