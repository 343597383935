const AccountUpgradeDowngradeAppView = () =>
  import(
    // @ts-ignore
    /*webpackChunkName: "AccountUpgradeDowngradeAppView"*/ "../../../views/app/AccountUpgradeDowngradeApp.vue"
  );
export default {
  path: "account/:action",
  name: "account-upgrade-downgrade",
  component: AccountUpgradeDowngradeAppView,
  props: true,
  meta: {
    requiresAuth: true,
    requiresEntitlement: true,
    isAmbiguous: false,
  },
};
