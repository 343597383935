const AccountCancelAppView = () =>
  import(
    /*webpackChunkName: "AccountCancelAppView"*/ "../../../views/app/profiles/CancelMembershipApp.vue"
  );

export default {
  path: "account/cancel",
  name: "account-cancel",
  component: AccountCancelAppView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: true,
    isAmbiguous: false,
  },
};
