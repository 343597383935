const LinkAppView = () =>
  import(/*webpackChunkName: "LinkAppView"*/ "../../../views/app/LinkApp.vue");
export const link = {
  path: "link",
  name: "link",
  component: LinkAppView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: true,
    isAmbiguous: false,
  },
};

export const linkCode = {
  path: "link/:codeUrl",
  name: "linkCode",
  component: LinkAppView,
  props: true,
  meta: {
    requiresAuth: true,
    requiresEntitlement: true,
    isAmbiguous: false,
  },
};
