const BrowseByCountryAppView = () =>
  import(
    /*webpackChunkName: "BrowseByCountryAppView"*/ "../../../views/app/BrowseByCountryApp.vue"
  );
export default {
  path: "browse-by-country",
  name: "browse-by-country",
  alias: "navegar-por-pais",
  component: BrowseByCountryAppView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: true,
    isAmbiguous: false,
  },
};
