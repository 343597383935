const ResetPasswordView = () =>
  import(
    /*webpackChunkName: "ResetPasswordView"*/ "../../../views/sales/ResetPasswordView.vue"
  );
const ForgotPassword = () =>
  import(
    /*webpackChunkName: "ForgotPassword"*/ "../../../views/sales/ForgotPassword.vue"
  );

export const resetPasswordToken = {
  path: "reset-password/:token/:email",
  name: "reset-password-token",
  alias: ["reiniciar-contrasena/:token/:email"],
  component: ResetPasswordView,
  meta: { requiresAuth: false, requiresEntitlement: false },
};

export const forgotPassword = {
  path: "forgot-password",
  name: "ForgotPassword",
  alias: ["olvide-contrasena"],
  component: ForgotPassword,
  meta: {
    requiresAuth: false,
    requiresEntitlement: false,
  },
};

export const forgotPasswordSent = {
  path: "forgot-password/sent",
  name: "ForgotPasswordSent",
  alias: ["olvide-contrasena-enviado"],
  component: ForgotPassword,
  props: () => ({
    sent: true,
  }),
  meta: {
    requiresAuth: false,
    requiresEntitlement: false,
  },
};
