const GetTheFullExperienceView = () =>
  import(
    /*webpackChunkName: "RegisterView"*/ "../../../views/sales/GetTheFullExperienceView.vue"
  );

export default {
  path: "get-the-full-experience",
  name: "getTheFullExperience",
  alias: ["obten-la-experiencia-completa"],
  component: GetTheFullExperienceView,
  props: (route: any) => ({ poster: route.query.p }),
  meta: {
    requiresAuth: false,
    requiresEntitlement: false,
  },
};
