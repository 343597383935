const DiscoverView = () =>
  import(
    /*webpackChunkName: "DiscoverView"*/ "../../../views/sales/DiscoverView.vue"
  );
export default {
  path: "discover",
  name: "discover",
  alias: ["descubre"],
  component: DiscoverView,
  meta: {
    requiresAuth: false,
    requiresEntitlement: false,
  },
};
