export default [
  {
    path: "/",
    redirect: "/es/",
  },
  {
    path: "/title/:slug",
    redirect: function (to: any) {
      const url = "/es/peliculas/" + to.params.slug;
      return { path: url };
    },
  },
];
