const HomeAppView = () =>
  import(/*webpackChunkName: "HomeAppView"*/ "../../../views/app/HomeApp.vue");

export const homeRoute = {
  path: "home",
  name: "home-app",
  component: HomeAppView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: true,
    isAmbiguous: false,
  },
};
