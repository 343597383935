// @ts-ignore
import { UserStore } from "@/stores/userStore";

const AssetPlayerAppView = () =>
  import(
    /*webpackChunkName: "AssetPlayerAppView"*/ "../../../views/app/AssetPlayerApp.vue"
  );
const checkUserEntitlement = (to: any, from: any, next: any) => {
  const userStore = UserStore();
  if (!userStore.isLoggedIn) {
    const assetPage = to.path.replace("/play", "");
    next({ path: assetPage });
  } else {
    next();
  }
};
export const moviePlay = {
  path: "movie/:slug/play",
  name: "movie-play",
  alias: [
    "pelicula/:slug/play/",
    "movies/:slug/play/",
    "peliculas/:slug/play/",
  ],
  props: (route: { params: { slug: any } }) => ({
    slug: route.params.slug,
    type: "movie",
  }),
  component: AssetPlayerAppView,
  beforeEnter: checkUserEntitlement,
  meta: {
    isPlayer: true,
    requiresAuth: false,
    requiresEntitlement: false,
    isAmbiguous: true,
    lang: "en",
  },
};

export const seriesPlay = {
  path: "series/:slug/play",
  name: "series-play",
  alias: "serie/:slug/play/",
  props: (route: { params: { slug: any } }) => ({
    slug: route.params.slug,
    type: "series",
  }),
  component: AssetPlayerAppView,
  beforeEnter: checkUserEntitlement,
  meta: {
    isPlayer: true,
    requiresAuth: false,
    requiresEntitlement: false,
    isAmbiguous: true,
  },
};

export const moviePlayTrailer = {
  path: "movie/:slug/play/trailer",
  name: "movie-play-trailer",
  alias: [
    "peliculas/:slug/play/trailer",
    "pelicula/:slug/play/trailer",
    "movies/:slug/play/trailer",
    "movie/:slug/play/trailer",
  ],
  props: (route: { params: { slug: any } }) => ({
    slug: route.params.slug,
    type: "trailer",
  }),
  component: AssetPlayerAppView,
  meta: {
    isPlayer: true,
    requiresAuth: false,
    requiresEntitlement: false,
    isAmbiguous: true,
  },
};

export const seriesPlayTrailer = {
  path: "series/:slug/play/trailer",
  name: "series-play-trailer",
  alias: ["serie/:slug/play/trailer"],
  props: (route: { params: { slug: any } }) => ({
    slug: route.params.slug,
    type: "trailer",
  }),
  component: AssetPlayerAppView,
  meta: {
    isPlayer: true,
    requiresAuth: false,
    requiresEntitlement: false,
    isAmbiguous: true,
  },
};

export const seriesPlayEpisode = {
  path: "series/:slug/season-:season/episode-:episode/play",
  name: "series-play-episode",
  alias: [
    "serie/:slug/season-:season/episode-:episode/play",
    "serie/:slug/temporada-:season/episodio-:episode/play",
    "series/:slug/temporada-:season/episodio-:episode/play",
  ],
  props: (route: {
    params: { slug: string; episode: string; season: string; type: "series" };
  }) => ({
    slug: route.params.slug,
    episode: route.params.episode,
    season: route.params.season,
    type: "series",
  }),
  component: AssetPlayerAppView,
  beforeEnter: checkUserEntitlement,
  meta: {
    isPlayer: true,
    requiresAuth: false,
    requiresEntitlement: false,
    isAmbiguous: true,
  },
};
