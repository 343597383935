const HomeView = () =>
  // @ts-ignore
  import(/*webpackChunkName: "HomeView"*/ "@/views/sales/HomeView.vue");

export default {
  path: "",
  name: "home",
  alias: ["index.html", "notfound"],
  component: HomeView,
  meta: {
    requiresAuth: false,
    requiresEntitlement: false,
  },
};
