const SearchAppView = () =>
  import(
    /*webpackChunkName: "SearchAppView"*/ "../../../views/app/SearchApp.vue"
  );
export default {
  path: "search",
  name: "search",
  alias: "buscar",
  component: SearchAppView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: true,
    isAmbiguous: false,
  },
};
