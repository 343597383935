import home from "./not_auth/home";
import miff from "./not_auth/miff";
import premiosplatino from "./not_auth/pl";
import freemium from "./not_auth/freemium";

import { movie, serie } from "./ambiguous/movies";
import episode from "./ambiguous/episode";
import aboutUs from "./ambiguous/aboutUs";
import termsAndConditions from "./ambiguous/termsAndConditions";
import privacyPolicy from "./ambiguous/privacyPolicy";
import authSSO from "./not_auth/authSSO";
import {
  forgotPassword,
  forgotPasswordSent,
  resetPasswordToken,
} from "./not_auth/forgotPassword";
import login from "./not_auth/login";
import register from "./not_auth/register";
import discover from "./not_auth/discover";
import getTheFullExperience from "./not_auth/getTheFullExperience";
import browse from "./not_auth/browse";
import countryMovies from "./not_auth/countryMovies";
import privacyRequest from "./not_auth/privacyRequest";
import plans from "./ambiguous/plans";

// AUTH
import { checkout, checkoutCyclePlan } from "./auth/checkout";
import { homeRoute } from "./auth/homeRoute";
import { browseBySeries, browseByMovie } from "./auth/browseByType";
import live from "./auth/live";
import search from "./auth/search";
import preferences from "./auth/preferences";
import logout from "./auth/logout";
import plansLogged from "./auth/plansLogged";

import account from "./auth/account";
import accountCancel from "./auth/accountCancel";

import { link, linkCode } from "./auth/link";

import {
  moviePlay,
  moviePlayTrailer,
  seriesPlayEpisode,
  seriesPlayTrailer,
  seriesPlay,
} from "./auth/assetsPlayer";

import { profileManage, profileManageAdd } from "./auth/profileManage";
import profiles from "./auth/profiles";
import browseByCountry from "./auth/browseByCountry";
import myList from "./auth/myList";
import miami from "./not_auth/miami";
import accountUpgradeDowngrade from "./auth/accountUpgradeDowngrade";

const allRoutes = [
  home,
  miff,
  miami,
  premiosplatino,
  freemium,

  movie,
  serie,
  episode,
  aboutUs,
  termsAndConditions,
  privacyPolicy,
  authSSO,
  forgotPassword,
  forgotPasswordSent,
  resetPasswordToken,
  login,
  register,
  getTheFullExperience,
  discover,
  browse,
  countryMovies,
  privacyRequest,
  plans,
  link,
  linkCode,

  //auth
  plansLogged,
  logout,
  checkout,
  checkoutCyclePlan,
  homeRoute,
  browseBySeries,
  browseByMovie,
  live,
  search,
  preferences,
  browseByCountry,
  myList,
  account,
  accountCancel,
  accountUpgradeDowngrade,

  // player
  moviePlay,
  moviePlayTrailer,
  seriesPlayEpisode,
  seriesPlayTrailer,
  seriesPlay,

  //profile
  profileManage,
  profileManageAdd,
  profiles,
];

export default allRoutes;
