const LiveTvAppView = () =>
  import(
    /*webpackChunkName: "LiveTvAppView"*/ "../../../views/app/LivePageApp.vue"
  );
export default {
  path: "live",
  name: "live",
  component: LiveTvAppView,
  meta: {
    requiresAuth: true,
    isAmbiguous: false,
  },
};
