const fromUrls = [
  "/country/peru&lang=es",
  "/pais/peru",
  "/pais/peru&lang=es",
  "/country/argentina&lang=es",
  "/pais/argentina",
  "/pais/argentina&lang=es",
  "/country/colombia&lang=es",
  "/pais/colombia",
  "/pais/colombia&lang=es",
  "/country/dominican-republic&lang=es",
  "/pais/dominican-republic",
  "/pais/dominican-republic&lang=es",
  "/country/espana&lang=es",
  "/country/spain&lang=es",
  "/pais/espana",
  "/pais/espana&lang=es",
  "/pais/spain",
  "/pais/spain&lang=es",
  "/country/mexico&lang=es",
  "/pais/mexico",
  "/pais/mexico&lang=es",
  "/country/republica-dominicana&lang=es",
  "/pais/republica-dominicana",
  "/pais/republica-dominicana&lang=es",
  "/country/venezuela&lang=es",
  "/pais/venezuela",
  "/pais/venezuela&lang=es",
  "/country/pavo&lang=es",
  "/country/turkey&lang=es",
  "/country/turquia&lang=es",
  "/pais/pavo",
  "/pais/pavo&lang=es",
  "/pais/turkey",
  "/pais/turkey&lang=es",
  "/pais/turquia",
  "/pais/turquia&lang=es",
  "/country/peru",
  "/country/peru&lang=en",
  "/pais/peru&lang=en",
  "/country/argentina",
  "/country/argentina&lang=en",
  "/pais/argentina&lang=en",
  "/country/colombia",
  "/country/colombia&lang=en",
  "/pais/colombia&lang=en",
  "/country/dominican-republic",
  "/country/dominican-republic&lang=en",
  "/country/republica-dominicana",
  "/country/republica-dominicana&lang=en",
  "/pais/dominican-republic&lang=en",
  "/pais/republica-dominicana&lang=en",
  "/country/mexico",
  "/country/mexico&lang=en",
  "/pais/mexico&lang=en",
  "/country/espana",
  "/country/espana&lang=en",
  "/country/spain",
  "/country/spain&lang=en",
  "/pais/espana&lang=en",
  "/pais/spain&lang=en",
  "/country/pavo",
  "/country/pavo&lang=en",
  "/country/turkey",
  "/country/turkey&lang=en",
  "/country/turquia",
  "/country/turquia&lang=en",
  "/pais/pavo&lang=en",
  "/pais/turkey&lang=en",
  "/pais/turquia&lang=en",
  "/country/venezuela",
  "/country/venezuela&lang=en",
  "/pais/venezuela&lang=en",
];

const toUrls = [
  "/tendencias/es/mejores-peliculas",
  "/tendencias/es/mejores-peliculas",
  "/tendencias/es/mejores-peliculas",
  "/tendencias/es/mejores-peliculas/argentina",
  "/tendencias/es/mejores-peliculas/argentina",
  "/tendencias/es/mejores-peliculas/argentina",
  "/tendencias/es/mejores-peliculas/colombia",
  "/tendencias/es/mejores-peliculas/colombia",
  "/tendencias/es/mejores-peliculas/colombia",
  "/tendencias/es/mejores-peliculas/dominican-republic",
  "/tendencias/es/mejores-peliculas/dominican-republic",
  "/tendencias/es/mejores-peliculas/dominican-republic",
  "/tendencias/es/mejores-peliculas/espana",
  "/tendencias/es/mejores-peliculas/espana",
  "/tendencias/es/mejores-peliculas/espana",
  "/tendencias/es/mejores-peliculas/espana",
  "/tendencias/es/mejores-peliculas/espana",
  "/tendencias/es/mejores-peliculas/espana",
  "/tendencias/es/mejores-peliculas/mexico",
  "/tendencias/es/mejores-peliculas/mexico",
  "/tendencias/es/mejores-peliculas/mexico",
  "/tendencias/es/mejores-peliculas/republica-dominicana",
  "/tendencias/es/mejores-peliculas/republica-dominicana",
  "/tendencias/es/mejores-peliculas/republica-dominicana",
  "/tendencias/es/mejores-peliculas/venezuela",
  "/tendencias/es/mejores-peliculas/venezuela",
  "/tendencias/es/mejores-peliculas/venezuela",
  "/tendencias/es/mejores-series-de-tv/turquia",
  "/tendencias/es/mejores-series-de-tv/turquia",
  "/tendencias/es/mejores-series-de-tv/turquia",
  "/tendencias/es/mejores-series-de-tv/turquia",
  "/tendencias/es/mejores-series-de-tv/turquia",
  "/tendencias/es/mejores-series-de-tv/turquia",
  "/tendencias/es/mejores-series-de-tv/turquia",
  "/tendencias/es/mejores-series-de-tv/turquia",
  "/tendencias/es/mejores-series-de-tv/turquia",
  "/trends/en/top-movies",
  "/trends/en/top-movies",
  "/trends/en/top-movies",
  "/trends/en/top-movies/argentina",
  "/trends/en/top-movies/argentina",
  "/trends/en/top-movies/argentina",
  "/trends/en/top-movies/colombia",
  "/trends/en/top-movies/colombia",
  "/trends/en/top-movies/colombia",
  "/trends/en/top-movies/dominican-republic",
  "/trends/en/top-movies/dominican-republic",
  "/trends/en/top-movies/dominican-republic",
  "/trends/en/top-movies/dominican-republic",
  "/trends/en/top-movies/dominican-republic",
  "/trends/en/top-movies/dominican-republic",
  "/trends/en/top-movies/mexico",
  "/trends/en/top-movies/mexico",
  "/trends/en/top-movies/mexico",
  "/trends/en/top-movies/spain",
  "/trends/en/top-movies/spain",
  "/trends/en/top-movies/spain",
  "/trends/en/top-movies/spain",
  "/trends/en/top-movies/spain",
  "/trends/en/top-movies/spain",
  "/trends/en/top-movies/turkey",
  "/trends/en/top-movies/turkey",
  "/trends/en/top-movies/turkey",
  "/trends/en/top-movies/turkey",
  "/trends/en/top-movies/turkey",
  "/trends/en/top-movies/turkey",
  "/trends/en/top-movies/turkey",
  "/trends/en/top-movies/turkey",
  "/trends/en/top-movies/turkey",
  "/trends/en/top-movies/venezuela",
  "/trends/en/top-movies/venezuela",
  "/trends/en/top-movies/venezuela",
];
export default [
  {
    path: "/:countrySegment(country|pais)/:country",
    name: "country-redirects",
    beforeEnter: (to: any) => {
      window.location.replace(toUrls[fromUrls.indexOf(to.path)]);
    },
  },
];
