const EpisodePageView = () =>
  import(
    // @ts-ignore
    /*webpackChunkName: "EpisodePageView"*/ "@/views/tittle/EpisodePage.vue"
  );

const episode = {
  path: "series/:slug/season-:season/episode-:episode",
  name: "episode",
  props: (route: { params: { slug: any; season: any; episode: any } }) => ({
    slug: route.params.slug,
    season: route.params.season,
    episode: route.params.episode,
    type: "episode",
  }),
  component: EpisodePageView,
  meta: { requiresAuth: false, requiresEntitlement: false },
};
export default episode;
