const ProfileManageAppView = () =>
  import(
    /*webpackChunkName: "ProfileManageAppView"*/ "../../../views/app/profiles/ProfileManageApp.vue"
  );

export const profileManage = {
  path: "profile/manage",
  name: "profile-manage",
  props: () => ({
    action: "edit",
  }),
  component: ProfileManageAppView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: true,
    isAmbiguous: false,
  },
};

export const profileManageAdd = {
  path: "profile/add",
  name: "profile-add",
  props: () => ({
    action: "add",
  }),
  component: ProfileManageAppView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: true,
    isAmbiguous: false,
  },
};
