// @ts-ignore
import { UserStore } from "@/stores/userStore";
// @ts-ignore
import Cookie from "@/services/Cookie";

const MoviePageView = () =>
  // @ts-ignore
  import(/*webpackChunkName: "MoviePageView"*/ "@/views/tittle/MoviePage.vue");

// @ts-ignore
const MoviePageAppView = () =>
  import(
    // @ts-ignore
    /*webpackChunkName: "MoviePageAppView"*/ "@/views/app/MoviePageApp.vue"
  );

const baseRoute = {
  path: ":segmentBase(peliculas|pelicula|movies|movie)/:slug",
  name: "peliculas",
  component: MoviePageView,
  meta: {
    requiresAuth: false,
    requiresEntitlement: false,
    isAmbiguous: true,
  },
  beforeEnter: (to: any, from: any, next: any) => {
    if (
      to.params.locale === "es" &&
      (to.params.segmentBase === "movie" || to.params.segmentBase === "movies")
    ) {
      window.location.replace(
        `/${to.params.locale}/peliculas/${to.params.slug}`
      );
    } else if (
      to.params.locale === "en" &&
      (to.params.segmentBase === "pelicula" ||
        to.params.segmentBase === "peliculas")
    ) {
      window.location.replace(`/${to.params.locale}/movies/${to.params.slug}`);
    }
    const userStore = UserStore();
    if ((userStore.isEntitled || userStore.trialEntitlement) && userStore.isLoggedIn) {
      to.matched[1].components.default = MoviePageAppView;
      if(Cookie.getCookie("flx_visited_title_page") !== "") {
        Cookie.erase("flx_visited_title_page");
      }
    } else {
      to.matched[1].components.default = MoviePageView;
      Cookie.createCookie(
        "flx_visited_title_page",
        to.fullPath,
        (new Date()).getTime() + 60 * 60 * 1000,
        "/",
        import.meta.env.DEV ? "localhost" : ".flixlatino.com"
      );
    }
    next();
  },
};

export const movie = {
  ...baseRoute,
  props: (route: { params: { slug: any } }) => ({
    slug: route.params.slug,
    type: "movie",
  }),
};

export const serie = {
  ...baseRoute,
  path: "series/:slug",
  name: "series",
  alias: ["serie/:slug"],
  props: (route: { params: { slug: any } }) => ({
    slug: route.params.slug,
    type: "series",
  }),
};

export default baseRoute;
