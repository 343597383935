const BrowseByTypeAppView = () =>
  import(
    /*webpackChunkName: "BrowseByTypeAppView"*/ "../../../views/app/BrowseByTypeApp.vue"
  );

export const browseBySeries = {
  path: "browse/series",
  name: "browse-series",
  alias: "navegar/series",
  props: () => ({
    type: "series",
  }),
  component: BrowseByTypeAppView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: true,
    isAmbiguous: false,
  },
};

export const browseByMovie = {
  path: "browse/movies",
  name: "browse-movies",
  alias: "navegar/peliculas",
  props: () => ({
    type: "movie",
  }),
  component: BrowseByTypeAppView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: true,
    isAmbiguous: false,
  },
};
