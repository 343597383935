const ProfileSelectAppView = () =>
  import(
    /*webpackChunkName: "ProfileSelectAppView"*/ "../../../views/app/ProfileSelectApp.vue"
  );

export default {
  path: "profiles",
  name: "profiles",
  component: ProfileSelectAppView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: true,
    isAmbiguous: false,
  },
};
