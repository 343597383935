const PreferencesAppView = () =>
  import(
    // @ts-ignore
    /*webpackChunkName: "PreferencesAppView"*/ "../../../views/app/PreferencesApp.vue"
  );
export default {
  path: "account",
  name: "account",
  component: PreferencesAppView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: true,
    isAmbiguous: false,
  },
};
