const MiffView = () =>
  // @ts-ignore
  import(/*webpackChunkName: "MiffView"*/ "@/views/sales/MiamiView.vue");

export default {
  path: "miami",
  name: "miami",
  alias: [],
  component: MiffView,
  meta: {
    requiresAuth: false,
    requiresEntitlement: false,
  },
};
