const LoginView = () =>
  import(
    /*webpackChunkName: "LoginView"*/ "../../../views/sales/LoginView.vue"
  );
export default {
  path: "login",
  name: "login",
  alias: ["iniciar-sesion"],
  component: LoginView,
  meta: {
    requiresAuth: false,
    requiresEntitlement: false,
  },
};
