const AboutUsView = () =>
  // @ts-ignore
  import(/*webpackChunkName: "AboutUsView"*/ "@/views/sales/AboutUsView.vue");

export default {
  path: "about-us",
  name: "about-us",
  alias: ["sobre-nosotros"],
  component: AboutUsView,
  meta: {
    requiresAuth: false,
    requiresEntitlement: false,
    isAmbiguous: true,
  },
};
