// @ts-ignore
import { UserStore } from "@/stores/userStore";
// @ts-ignore
import { ProfileStore } from "@/stores/profileStore";

const HomeView = () =>
  import(
    /*webpackChunkName: "LogoutView"*/ "../../../views/sales/HomeView.vue"
  );
export default {
  path: "logout",
  name: "logout",
  component: HomeView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: false,
  },
  beforeEnter: (to: any, from: any, next: any) => {
    const userStore = UserStore();
    const profilesStore = ProfileStore();
    userStore.logout();
    profilesStore.destroy();
    next({ name: "home" });
  },
};
