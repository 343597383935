const PrivacyPolicyView = () =>
  import(
    /*webpackChunkName: "PrivacyPolicyView"*/ "../../../views/sales/PrivacyPolicyView.vue"
  );

export default {
  path: "privacy-policy",
  name: "privacy-policy",
  alias: ["politica-de-privacidad"],
  component: PrivacyPolicyView,
  meta: {
    requiresAuth: false,
    requiresEntitlement: false,
    isAmbiguous: true,
  },
};
