const TermsAndConditionsView = () =>
  import(
    // @ts-ignore
    /*webpackChunkName: "TermsAndConditionsView"*/ "@/views/sales/TermsAndConditionsView.vue"
  );

export default {
  path: "terms-and-conditions",
  name: "terms-and-conditions",
  alias: ["termsofuse", "terminos-y-condiciones"],
  component: TermsAndConditionsView,
  meta: { requiresAuth: false, requiresEntitlement: false, isAmbiguous: true },
};
