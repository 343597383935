const PlanDetailsView = () =>
  import(
    /*webpackChunkName: "PlanDetailsView"*/ "../../../views/sales/PlanDetailsView.vue"
  );
export default {
  path: "plans",
  name: "plans",
  alias: ["planes"],
  component: PlanDetailsView,
  meta: {
    requiresAuth: false,
    requiresEntitlement: false,
  },
};
