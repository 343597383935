import { UserStore } from "../../../stores/userStore";

const CheckoutView = () =>
  import(
    /*webpackChunkName: "CheckoutView"*/ "../../../views/sales/CheckoutView.vue"
  );
export const checkout = {
  path: "checkout",
  name: "checkout-clean",
  component: CheckoutView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: true,
    hideMenuOptions: true,
  },
  props: (route: { params: { cycle: any; plan: any } }) => ({
    cycle: "monthly",
    plan: "premium",
  }),
  beforeEnter: (to: any, from: any, next: any) => {
    if (to.params.plan === "free") {
      return { name: "live" };
    }
    next();
  },
};

export const checkoutCyclePlan = {
  path: "checkout/:cycle/:plan",
  name: "checkout",
  component: CheckoutView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: false,
    hideMenuOptions: true,
  },
  props: (route: { params: { cycle: any; plan: any } }) => ({
    cycle: route.params.cycle ?? "monthly",
    plan: route.params.plan ?? "premium",
  }),
  beforeEnter: (to: any, from: any, next: any) => {
    if (to.params.plan === "free") {
      next("/live");
    }
    next();
  },
};
