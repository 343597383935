const CountryMoviesView = () =>
  import(
    /*webpackChunkName: "CountryMoviesView"*/ "../../../views/generic/CountryMovies.vue"
  );
export default {
  path: "discover/:country",
  name: "country-movies",
  alias: [
    "descubre/:country",
    "country-movies/:country",
    "country-movies/:country/:garbage?",
  ],
  props: true,
  component: CountryMoviesView,
  meta: { requiresAuth: false, requiresEntitlement: false },
};
