const PlView = () =>
  // @ts-ignore
  import(/*webpackChunkName: "PlView"*/ "@/views/sales/PlView.vue");

export default {
  path: "premiosplatino",
  name: "premiosplatino",
  alias: [],
  component: PlView,
  meta: {
    requiresAuth: false,
    requiresEntitlement: false,
  },
};
