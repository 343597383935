const PreferencesAppView = () =>
  import(
    /*webpackChunkName: "PreferencesAppView"*/ "../../../views/app/PreferencesApp.vue"
  );

export default {
  path: "preferences",
  name: "preferences",
  alias: ["account"],
  component: PreferencesAppView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: true,
    isAmbiguous: false,
  },
};
