const MyListAppView = () =>
  import(
    /*webpackChunkName: "MyListAppView"*/ "../../../views/app/MyListApp.vue"
  );
export default {
  path: "my-list",
  name: "my-list",
  alias: "mi-lista",
  component: MyListAppView,
  meta: {
    requiresAuth: true,
    requiresEntitlement: true,
    isAmbiguous: false,
  },
};
