const FreemiumView = () =>
  // @ts-ignore
  import(/*webpackChunkName: "FreemiumView"*/ "@/views/sales/FreemiumView.vue");

export default {
  path: "free",
  name: "free",
  alias: ["gratis"],
  component: FreemiumView,
  beforeEnter: (to: any, from: any, next: any) => {
    if (to.path !== "/en/free" && to.path !== "/es/gratis") {
      const targetPath = to.path === "/free" ? "/en/free" : "/es/gratis";
      next({ path: targetPath, query: to.query });
    } else {
      next();
    }
  },
  meta: {
    requiresAuth: false,
    requiresEntitlement: false,
  },
};
