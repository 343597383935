const PrivacyRequestView = () =>
  import(
    /*webpackChunkName: "PrivacyRequestView"*/ "../../../views/sales/PrivacyRequestView.vue"
  );

export default {
  path: "privacy-request",
  name: "privacy-request",
  alias: ["privacy-request"],
  component: PrivacyRequestView,
  meta: {
    requiresAuth: false,
    requiresEntitlement: false,
    isAmbiguous: true,
  },
};
